<template>
    <div class="app-container">
        <!-- 选项卡 -->
        <el-tabs type="border-card">
            <el-tab-pane label="成员管理">
                <!-- 查询表单 -->
                <el-form :inline="true" :model="queryForm" ref="queryForm" size="small" style="margin-bottom: 20px">
                    <el-form-item prop="keyWord">
                        <el-input v-model="queryForm.keyWord" placeholder="请输入账号或姓名或手机号" clearable style="width: 300px;" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" @click="querySubmit">查询</el-button>
                    </el-form-item>
                </el-form>
                <!-- 功能按钮组 -->
                <el-row :gutter="20" style="margin-bottom: 7px">
                    <el-col :span="1.5">
                        <el-button type="primary" size="small" icon="el-icon-plus" plain
                            @click="handleAdd">添加总馆成员</el-button>
                    </el-col>
                    <el-col :span="1.5">
                        <el-button type="primary" size="small" icon="el-icon-plus" plain
                            @click="handleAdd('part')">添加分馆成员</el-button>
                    </el-col>
                </el-row>
                <!-- 表格数据 -->
                <el-table ref="table" :data="tableData" @selection-change="handleSelectionChange" >
                    <el-table-column prop="createTime" label="加入时间" width="180px" />
                    <el-table-column prop="username" label="姓名" width="180px" />
                    <el-table-column prop="bid" label="角色权限" width="160px">
                        <template slot-scope="scope">
                            {{ scope.row.bid == 0 ? '总馆图书管理员' : '分馆图书管理员' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="电话号码" />
                    <el-table-column prop="email" label="邮箱" show-overflow-tooltip />
                    <el-table-column label="操作" width="180px">
                        <template slot-scope="scope">
                           <div style="display: flex;"> 
                            <div v-if="username == scope.row.username">
                                <el-button size="mini" icon="el-icon-delete" type="info" plain
                                >暂无</el-button>
                            </div>
                            <div v-else>
                                <el-button size="mini" icon="el-icon-delete" type="danger" plain
                                @click="handleDelete(scope.row)">移除</el-button>
                            </div>
                            <div> <el-button  size="mini" icon="el-icon-edit" type="edit" @click="editAdmin(scope.row)">编辑</el-button></div>
                           </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="page.current" :page-size="page.size" :page-sizes="[2, 5, 10, 20, 100]"  :page-count="page.pageNum"
                    layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                </el-pagination>
            </el-tab-pane>
        </el-tabs>
        <!-- 编辑管理员 -->
        <el-dialog :title="editAdminForm.username" :modal="true" :visible.sync="editAdminDialogVisible" width="30%" append-to-body >
                <el-form ref="dialogForm" :model="editAdminForm" :rules="dialogFormRules" label-width="80px" label-position="left"> 
                        <el-form-item label="用户名" prop="username">
                            <el-input v-model="editAdminForm.username"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号" prop="phone">
                            <el-input v-model="editAdminForm.phone"></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱" prop="email">
                            <el-input v-model="editAdminForm.email"></el-input>
                        </el-form-item>
                        
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="cancelEditAdmin('dialogForm')">取 消</el-button>
                    <el-button type="primary" @click="submitEditAdmin('dialogForm')">确 定</el-button>
                </div>
        </el-dialog>
        <!-- 编辑或添加对话框 -->
        <el-dialog :title="dialogTitle" :modal="false" :visible.sync="dialogFormVisible" width="55%" append-to-body>
            <el-form ref="dialogForm" :model="dialogForm" label-width="90px" inline :rules="dialogFormRules">
                <el-form-item label="姓名" prop="username">
                    <el-input v-model="dialogForm.username" autocomplete="off" placeholder="请输入姓名" />
                </el-form-item>
                <el-form-item label="电话号码" prop="phone" >
                    <el-input v-model="dialogForm.phone" maxlength="11" autocomplete="off" placeholder="请输入电话"/>
                </el-form-item>
                <el-form-item v-if="dialogType == 'add' || dialogType == 'part'" label="密码" prop="password" >
                    <el-input v-model="dialogForm.password" autocomplete="off" placeholder="请输入密码"/>
                </el-form-item>
                <el-form-item label="账号" prop="account"  >
                    <el-input v-model="dialogForm.account" autocomplete="off" placeholder="请输入账号，用于登录"/>
                </el-form-item>
               
                
                <el-form-item label="邮箱" prop="email" >
                    <el-input v-model="dialogForm.email" autocomplete="off" placeholder="请输入邮箱"/>
                </el-form-item>
                <el-form-item label="选择分馆" prop="bid" v-if="dialogType === 'part'">
                    <el-row class="demo-autocomplete">
                        <el-col :span="30">
                            <el-autocomplete class="inline-input" v-model="dialogForm.bid" :fetch-suggestions="querySearch"
                                placeholder="请选择分馆" @select="handleSelect"></el-autocomplete>
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelDialog('dialogForm')">取 消</el-button>
                <el-button type="primary" @click="submitDialog('dialogForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { listAdmin, addManager, addAdmin, deleteAdmin, updateAdmin } from '@/api/admin'
import { listLibrary } from '@/api/library'
import { getUserName } from '@/utils/auth'

export default {
    data() {
        return {
            //添加验证信息
            dialogFormRules:{
                username:  [{required: true, message: '名字不能为空', trigger: 'blur'}],
                password:  [{required: true, message: '密码密码不能为空 ', trigger: 'blur'}],
                phone:     [{required: true,message: "手机号不能为空",trigger: "blur"},{pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, message: "请输入正确的手机号码", trigger: "blur"}],
                id:       [{required: true,message: "分管不能为空",trigger: "blur"}],
                email:     [{required: true,message: "邮箱不能为空",trigger: "blur"},{pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/, message: "请输入正确的邮箱", trigger: "blur"}],
                
            },

            //编辑管理员
            editAdminDialogVisible: false,
            editAdminForm:{
               
            },

            // 查询表单数据
            queryForm: {},
            // 表格数据
            tableData: [],
            // 多选数据
            multipleSelection: [],
            // 分页数据
            page: {
                current: 1, // 当前页
                size: 10, // 每页显示数
                total: undefined , // 总计
                pageNum:1 //页数
            },
            // 对话框类型[add|edit]
            dialogType: '',
            // 对话框是否可见
            dialogFormVisible: false,
            // 对话框标题
            dialogTitle: undefined,
            // 对话框数据
            dialogForm: {},
            // 选择分馆
            branchLibrary: [],
            // 添加填写分馆id
            bid: undefined,
        }
    },
    created() {
        this.getList()
        this.username = JSON.parse(getUserName())
    },
    updated(){
       if (this.tableData == []){
        const queryParams = { ...this.queryForm, page:1, size: this.page.size }
            listAdmin(queryParams).then(({ data }) => {
                this.tableData = data.adminData
                this.page.total = data.total
                this.page.current = data.current
                this.page.pageNum = data.pageNum
            })
       }
    },
    methods: {
        // 编辑管理员
        editAdmin(row){
            this.editAdminDialogVisible = true
           // console.log(row)
            this.editAdminForm = row
            


        },


        //============== 管理员API =============
        // 查询管理员列表
        getList() {
            const queryParams = { ...this.queryForm, page: this.page.page, size: this.page.size }
            listAdmin(queryParams).then(({ data }) => {
                this.tableData = data.adminData
                this.page.total = data.total
                this.page.current = data.current
                this.page.pageNum = data.pageNum
            })
        },
        // 添加总馆管理员
        addData() {
            this.dialogForm.account == null ? this.dialogForm.account = this.dialogForm.phone : this.dialogForm.account
            this.dialogForm.email == null ? this.dialogForm.email = this.dialogForm.phone+"@163.com" : this.dialogForm.email
            addManager(this.dialogForm).then(({ data }) => {
                if(data.code == '400'){
                    alert(data.message)
                    
                    this.dialogFormVisible = true
                }else{
                    this.msgSuccess(data)
                    this.getList()
                    
                    this.dialogFormVisible = false

                }
               // resetFields
               //this.$refs[dialogForm].resetFields()
            })
        },
        // 添加分馆
        addPart() {
            this.dialogForm.account == null ? this.dialogForm.account = this.dialogForm.phone : this.dialogForm.account
            this.dialogForm.email == null ? this.dialogForm.email = this.dialogForm.phone+"@163.com" : this.dialogForm.email
            addAdmin({ ...this.dialogForm, bid: this.bid }).then(({ data }) => {
                if(data.code == '400'){
                    alert(data.message)
                   
                    this.dialogFormVisible = true
                }else{
                    
                    this.msgSuccess(data)
                    this.getList()
                }
                //this.$refs[dialogForm].resetFields()

            })
        },
        // 删除管理员
        deleteData(data) {
            deleteAdmin(data).then(({ data }) => {
                this.msgSuccess(data)
                this.getList()
            })
        },

        //============== 查询表单 =================
        // 查询表单提交
        querySubmit() {
            this.getList()
        },

        //============== 表格 ==================
        // 多选改变
        handleSelectionChange(val) {
            this.multipleSelection = val
            this.editDisabled = !(val.length === 1)
            this.removeDisabled = !(val.length > 0)
        },
        // 删除行数据
        handleDelete(row) {
            let data = [row.id,]
            this.$confirm('确定要移除该成员？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.deleteData(data)
                })
                .catch(() => { })
        },
        // 添加数据
        handleAdd(state) {
            if (state === 'part') {
                this.dialogType = 'part'
            } else {
                this.dialogType = 'add'
            }
            // 清除对话框表单数据
            this.dialogForm = {}
            this.dialogTitle = '添加管理员'
            this.showDialog()
        },

        //============== 添加或编辑对话框 ===================
        // 显示对话框
        showDialog() {
            this.dialogFormVisible = true
        },
        // 对话框取消
        cancelDialog(dialogForm) {
            this.$refs[dialogForm].resetFields()
            this.dialogFormVisible = false
        },
        cancelEditAdmin(dialogForm) {
            this.$refs[dialogForm].resetFields()
            this.editAdminDialogVisible = false
        },
        // 对话框提交
        submitEditAdmin(dialogForm) {
            this.$refs[dialogForm].validate((valid,value) => {
                if(valid){
                    let parmas = { ...this.editAdminForm}
                    updateAdmin(parmas).then(res => {
                        if(res.code == 200){
                            this.$message({
                                message: '编辑成功',
                                type: 'success'
                            })
                            this.editAdminDialogVisible = false
                           // this.getAdminList()
                        }else{
                            this.$message({
                                message: '修改失败',
                                type: 'error'
                            })
                        }
                    })
                }else{
                    Object.values(value).forEach((item)=>{setTimeout(()=>{this.$message.error( item[0].message)},500)})  

                }
            })


        },
        submitDialog(dialogForm) {
            this.$refs[dialogForm].validate((valid,value) => {
                if(valid){
                    if (this.dialogType == 'add') {
                        this.addData()
                    } else if (this.dialogType == 'part') {
                        this.addPart()
                    } else {
                        this.updateData()
                    }
                }else{
                    Object.values(value).forEach((item)=>{setTimeout(()=>{this.$message.error( item[0].message)},500)})  

                }
            })


            
            //this.dialogFormVisible = false
        },
        // 选择分馆
        handleSelect(item) {
            console.log(item)
            this.bid = item.address
        },
        // 点击拉取分馆数据
        querySearch(queryString, cb) {
            var branchLibrary = this.branchLibrary;
            var results = queryString ? branchLibrary.filter(this.createFilter(queryString)) : branchLibrary;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        // 搜索数据
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        // 分馆信息
        loadAll() {
            listLibrary().then(({ data }) => {
                const newData = data.libraryBranchData.map(item => {
                    return {
                        value: item.branchName,
                        address: item.id
                    };
                });
                this.branchLibrary = newData;
            })
        },

        //=============== 分页 ==================
        // 每页显示数改变
        handleSizeChange(val) {
            this.page.size = val
            this.getList()
        },
        // 当前页改变
        handleCurrentChange(val) {
            this.page.page = val
            this.getList()
        }
    },
    mounted() {
        this.loadAll();
    },
}
</script>

<style></style>